<template>
    <div class="funding">
        <div class="content">
            <div class="info">
                <div class="info-item">
                    <span class="text1">拟融资额：</span>
                    <span class="text2">{{ info.amount }}万</span>
                </div>
                <div class="info-item">
                    <span class="text1">融资目的：</span>
                    <span class="text2">{{ info.purposeName.join(',') }}</span>
                </div>

                <div class="info-item">
                    <span class="text1">期望贷款期限：</span>
                    <span class="text2">{{ loanTerm }}</span>
                </div>
                <div class="info-item">
                    <span class="text1">联系人：</span>
                    <span class="text2">{{ info.contact }}</span>
                </div>
                <!-- <div class="info-item">
                    <span class="text1">电话：</span>
                    <span class="text2">{{ info.phone }}</span>
                </div> -->
                <div class="info-item">
                    <span class="text1">所在地区：</span>
                    <span class="text2">{{ info.address }}</span>
                </div>
            </div>
            <div class="time">
                {{ formatTime(info.createdAt, 'YYYY.MM.DD') }}
            </div>
        </div>

        <div class="edit-btn" v-if="isEdit">
            <el-button type="primary" size="small" plain @click.prevent="edit">编辑</el-button>
            <el-button size="small" plain @click.prevent="del">删除</el-button>
        </div>
        <div class="btn" v-else>
            <el-button type="primary" class="icon-button" @click.prevent="connectKefu" size="small" plain>
                <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                咨询客服
            </el-button>
            <el-button type="primary" @click.prevent="postMessage" class="icon-button" size="small" plain>
                <i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言
            </el-button>
        </div>
    </div>
</template>

<script>
import { loanTermOptions } from '../../utils/variables';
import comEvent from '../../mixins/comEvent';

export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        loanTerm() {
            let info = loanTermOptions.find(item => {
                return this.info.loanTerm == item.value;
            });

            return info ? info.label : '';
        }
    },
    mixins: [comEvent],
    methods: {
        edit() {
            this.$router.push({
                path: '/fundingEdit',
                query: {
                    id: this.info.id
                }
            });
        },
        del() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/funding/del/${this.info.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$emit('refreash');
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>

<style lang="less" scoped>
.funding {
    padding: 20px;
    border: 1px solid rgba(56, 85, 142, 0.04);
    margin: 10px 0;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 3px 12px 0px rgba(255, 135, 0, 0.2);
        border: 1px solid #ff8700;
    }
}
.info {
    .flex();
    flex-wrap: wrap;
    .info-item {
        padding-right: 30px;
        box-sizing: border-box;
        line-height: 32px;
        width: 33%;
        .flex();

        .text1 {
            font-size: 13px;
            color: #939599;
            display: inline-block;
            flex-shrink: 0;
        }

        .text2 {
            font-size: 13px;
            color: #00684f;
            margin-left: 10px;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.icon-button {
    line-height: 36px;
    display: flex;
}
.icon-button + .icon-button {
    margin-left: 0;
    margin-top: 16px;
}

.edit-btn {
    .flex();
    .el-button {
        &:first-child {
            width: 88px;
        }

        &:last-child {
            width: 56px;
        }
    }
}
.funding {
    .flex();
    .content {
        flex-grow: 1;
        padding-right: 180px;
    }
}

.time {
    font-size: 13px;
    color: #818999;
    line-height: 18px;
    margin-top: 20px;
}
</style>
